import React, { useEffect, useState, useRef } from 'react';
import MapPinIcon from '../components/Icons/MapPinIcon';
import PhoneIcon from '../components/Icons/PhoneIcon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import './contact.css';
import { inject, observer } from 'mobx-react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactPage = (observer(({ store }) => {
  const [captcha, setCaptha] = useState(null);
  const [formState, setFormState] = useState({
    name: null,
    email: null,
    title: null,
    body: null,
  });
  const [formValid, setFormVaild] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [messageSending, setMessageSending] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [titleEmpty, setTitleEmpty] = useState(false);
  const [bodyEmpty, setBodyEmpty] = useState(false);
  const [nameEmpty, setNameEmpty] = useState(false);
  useEffect(() => {
    store.closeMenu();
  }, []);

  const form = useRef();

  const nameInput = useRef();
  const emailInput = useRef();
  const titleInput = useRef();
  const bodyInput = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (formValid) {
      setMessageSending(true)
      emailjs.sendForm('service_uadwgze', 'template_ivwolcw', form.current, 'user_wyZYtBE41gja5GxFPe2Zy')
        .then((result) => {
          setMessageSending(false)
          setMessageSent(true)
        }, (error) => {
          setMessageSending(false)
          setMessageError(true)
        });
    };
  }

  const onChange = (value) => {
    setCaptha(value);
  }

  useEffect(() => {
    if (formState.email && formState.title && formState.body && !emailInvalid && captcha) {
      setFormVaild(true);
    } else {
      setFormVaild(false);
    }
  }, [formState, captcha])

  const emailValidation = () => {
    if (!emailInput.current.value) {
      setEmailEmpty(true);
      return;
    }
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(emailInput.current.value) === false) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
  }

  return (
    <Layout>
      <Seo title='Kontakt' description='Kontakt stranica kompanije Piperski D.O.O. Kontaktiraćemo Vas u najkraćem mogućem roku sa detaljima oko željene MRI zaštite ili izgradnje MRI sobe.' />
      <article>
        <div className="page-title page-title-bg-six">
          <h1>Kontakt</h1>
        </div>
        <section className="contact-wrapper">
          <div className="contact-icons-mobile">
            <div className='contact-box-mobile-wrapper'>
              <div className="contact-box-mobile">
                <PhoneIcon marginBottom={0} fill='#555' width={32} />
                <div className="contact-box-text-mobile">
                  <p>+381 63 862 1652</p>
                  <p>+381 64 1256 220</p>
                </div>
              </div>
              <div className="contact-box-mobile">
                <MapPinIcon marginBottom={0} fill='#555' width={32} />
                <div className="contact-box-text-mobile">
                  <p>Beograd, Srbija</p>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <div className="contact-form-title">
              <h3>Kontaktirajte nas</h3><div className="title-dash"></div>
            </div>
            <div className="contact-form-expl">
              <p className="mobile-expl" style={{ marginBottom: '18px' }}>Stojimo Vam na raspolaganju za sva pitanja i nedoumice. Dobijte procenu za Vaš projekat.</p>
              <p>Polja naznačena sa (*) su obavezna.</p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-row">
                <label className={nameEmpty ? 'input-error' : ''}>
                  Ime *
                  {nameEmpty && <span className="form-error">Polje je obavezno</span>}
                  <input ref={nameInput} onChange={(e) => {
                    setFormState({
                      ...formState,
                      name: nameInput.current.value
                    })
                    if (nameInput.current.value) {
                      setNameEmpty(false)
                    } else {
                      setNameEmpty(true)
                    }
                  }} onBlur={() => {
                    if (nameInput.current.value) {
                      setNameEmpty(false)
                    } else {
                      setNameEmpty(true)
                    }
                  }} type="text" name="name" id="name" />
                </label>
                <label>
                  Prezime
                  <input type="text" name="last_name" id="lastName" />
                </label>
              </div>
              <div className="form-row">
                <label>
                  Ime organizacije
                  <input type="text" name="organization_name" id="name" />
                </label>
                <label>
                  MRI proizvođač
                  <input type="text" name="mri" id="lastName" />
                </label>
              </div>
              <div className="form-row">
                <label className={emailEmpty || emailInvalid ? 'input-error' : ''}>
                  Mejl *
                  {emailInvalid && <span className="form-error">Mejl nije validan</span>}
                  {emailEmpty && <span className="form-error">Polje je obavezno</span>}
                  <input ref={emailInput} onChange={(e) => {
                    setFormState({
                      ...formState,
                      email: emailInput.current.value
                    })
                    if (emailInput.current.value) {
                      setEmailEmpty(false)
                    } else {
                      setEmailEmpty(true)
                      setEmailInvalid(false);
                    }
                    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (regex.test(emailInput.current.value) === false) {
                      // setEmailInvalid(true);
                    } else {
                      setEmailInvalid(false);
                    }
                  }} onBlur={emailValidation} type="text" name="email" id="email" />
                </label>
                <label>
                  Broj telefona
                  <input type="number" name="phone" id="phone" />
                </label>
              </div>
              <div className="form-row long-input">
                <label className={titleEmpty ? 'input-error' : ''}>
                  Naslov *
                  {titleEmpty && <span className="form-error">Polje je obavezno</span>}
                  <input ref={titleInput} onChange={(e) => {
                    setFormState({
                      ...formState,
                      title: titleInput.current.value
                    })
                    if (titleInput.current.value) {
                      setTitleEmpty(false)
                    } else {
                      setTitleEmpty(true)
                    }
                  }} onBlur={() => {
                    if (titleInput.current.value) {
                      setTitleEmpty(false)
                    } else {
                      setTitleEmpty(true)
                    }
                  }} type="text" name="subject" id="subject" />
                </label>
              </div>
              <div className="form-row long-input">
                <label className={bodyEmpty ? 'input-error' : ''}>
                  Poruka *
                  {bodyEmpty && <span className="form-error">Polje je obavezno</span>}
                  <textarea ref={bodyInput} onChange={(e) => {
                    setFormState({
                      ...formState,
                      body: bodyInput.current.value
                    })
                    if (bodyInput.current.value) {
                      setBodyEmpty(false)
                    } else {
                      setBodyEmpty(true)
                    }
                  }} onBlur={() => {
                    if (bodyInput.current.value) {
                      setBodyEmpty(false)
                    } else {
                      setBodyEmpty(true)
                    }
                  }} type="text" name="message" id="message" />
                </label>
              </div>
              <div style={messageSent ? { display: 'none' } : {}} className="captcha-wrapper">
                <ReCAPTCHA
                  sitekey="6LdljS4dAAAAAEThBkafThHlRLTBKeYh921pkyPL"
                  onChange={onChange}
                />
              </div>
              <div className="form-actions-wrapper">
                {!messageSending && !messageSent && !messageError && <div title={formValid ? 'Pošalji' : 'Pravilno popunite formu'} className={`form-send-button ${formValid ? '' : 'button-disabled'}`}>
                  <input type="submit" value="Pošalji" />
                </div>}
                {messageSending && <div className="loader-wrapper"> <div class="loader"></div></div>}
                {messageSent && <div className="message-sent"><p>Uspešno ste poslali mejl. Očekujte odgovor uskoro.</p></div>}
                {messageError && <div style={{ color: 'red' }} className="message-sent"><p>Došlo je do greške. Molimo osvežite stranicu i probajte ponovo.</p></div>}
              </div>
            </form>
          </div>
          <div className="contact-icons">
            <div className="contact-icons-title">
              <h4>Kontaktne Informacije</h4>
            </div>
            <div className="contact-icons-text">
              <p>
                Stojimo Vam na raspolaganju za sva pitanja i nedoumice. Dobijte procenu za Vaš projekat.
              </p>
            </div>
            <div className="contact-box">
              <MapPinIcon marginBottom={0} fill='#555' width={30} />
              <div className="contact-box-text">
                <p>Beograd, Srbija</p>
              </div>
            </div>
            <div className="contact-box">
              <PhoneIcon marginBottom={0} fill='#555' width={30} />
              <div className="contact-box-text">
                <p>+381 63 862 1652</p>
                <p>+381 64 0156 480</p>
              </div>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}))

export default inject('store')(ContactPage);
